import { getAnonymousWishlist } from '@server/requests/anonymousWishlist';
import { getWishlist } from '@server/requests/wishlist';

export const getCorrectWishlist = async ({
    authToken,
    anonymousWishlistId,
    anonymousWishlistToken,
    page = 1,
    pageSize = 50,
}: {
    authToken?: string;
    anonymousWishlistId?: number;
    anonymousWishlistToken?: string;
    page?: number;
    pageSize?: number;
}) => {
    if (authToken) {
        const wishlist = await getWishlist({
            token: authToken,
            page,
            pageSize,
        });
        return wishlist.getWishlist;
    }
    if (anonymousWishlistId && anonymousWishlistToken) {
        const anonymousWishlist = await getAnonymousWishlist({
            wishlistId: anonymousWishlistId,
            wishlistToken: anonymousWishlistToken,
        });
        return anonymousWishlist.getAnonymousWishlist;
    }

    return null;
};
