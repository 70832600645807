import type { EnvironmentConfig } from '@/config/env';

export default function buildUrl(
    envConfig: EnvironmentConfig,
    locale: string | undefined,
    path?: string | null,
) {
    let _locale = locale;
    if (envConfig.NEXT_PUBLIC_APP_LOCALE) _locale = undefined;
    if (!_locale && !path) return `/`;
    let pathname = path;
    if (pathname && pathname.startsWith('/')) pathname = pathname.slice(1);
    if (!pathname && _locale) return `/${_locale}`;
    if (!_locale && pathname) return `/${pathname}`;
    return `/${_locale}/${pathname}`;
}
